import isObject from 'lodash/isObject';

function getSocialModel(encodedPostUrl, text, subject) {
  let emailText;

  let twitterText;

  let linkedinText;

  if (isObject(text)) {
    twitterText = text.twitter;
    linkedinText = text.linkedin;
    emailText = text.email;
  } else {
    emailText = text;
    twitterText = text;
    linkedinText = text;
  }

  return [
    {
      property: 'facebook',
      url: `https://www.facebook.com/sharer.php?u=${encodedPostUrl}&app_id=178479832213933&display=popup`,
      popup: true,
      socialShare: {
        platform: 'Facebook',
        action: 'share'
      }
    },
    {
      property: 'twitter',
      url: `https://twitter.com/intent/tweet?url=${encodedPostUrl}&text=${twitterText}`,
      popup: true,
      socialShare: {
        platform: 'Twitter',
        action: 'tweet'
      },
      iconSVGBlack:
        'https://assets.fastcompany.com/image/upload/v1695923902/social-share-icon-twitter-in-circle.svg',
      iconSVGWhite:
        'https://assets.fastcompany.com/image/upload/v1695923902/social-share-icon-twitter.svg',
      iconSVGBlack2x:
        'https://assets.fastcompany.com/image/upload/v1696285191/social-share-icon_2x-twitter-in-circle.png',
      iconSVGWhite2x:
        'https://assets.fastcompany.com/image/upload/v1696285228/social-share-icon_2x-twitter.png'
    },
    {
      property: 'linkedin',
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedPostUrl}&media=${linkedinText}`,
      popup: true,
      socialShare: {
        platform: 'Linkedin',
        action: 'share'
      }
    },
    {
      property: 'bluesky',
      url: `https://bsky.app/intent/compose?text=${twitterText}+${encodedPostUrl}`,
      popup: true,
      socialShare: {
        platform: 'bluesky',
        action: 'share'
      }
    },
    {
      property: 'email',
      url: `mailto:?body=${emailText} ${encodedPostUrl}&subject=${subject ||
        emailText}`,
      icon: 'share-email',
      popup: false,
      socialShare: {
        platform: 'Email',
        action: 'email'
      }
    },
    {
      property: 'link',
      url: encodedPostUrl,
      icon: 'link',
      popup: false,
      socialShare: {
        platform: 'Copy',
        action: 'link'
      }
    }
  ];
}

export default getSocialModel;
